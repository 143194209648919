<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            API App {{ apiApp ? apiApp._id : '' }}
          </v-card-title>

          <v-card-text v-if="!loading && apiApp">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-simple-table>
                  <template #default>
                    <tbody>
                      <tr>
                        <td>Extern ID (App ID)</td>
                        <td>{{ apiApp.externalUuid }}</td>
                      </tr>

                      <tr>
                        <td>Sleutel (API Key)</td>
                        <td>****</td>
                      </tr>

                      <tr>
                        <td>Max requests per min</td>
                        <td>{{ apiApp.rateLimit }}</td>
                      </tr>

                      <tr>
                        <td>Organisatie</td>
                        <td>{{ organization.title }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  :to="{ name: 'apiApps' }"
                >
                  Terug
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,
      apiApp: null,
      breadcrumbs: [
        {
          text: 'Wolk dashboard',
          disabled: false,
          exact: true,
          to: { name: 'home' },
        },
        {
          text: 'API Apps',
          disabled: false,
          exact: true,
          to: { name: 'apiApps' },
        },
        {
          text: 'API App',
          disabled: true,
          exact: true,
          to: { name: 'apiAppsShow' },
        },
      ],
    };
  },
  beforeMount() {
    this.loadApiApp();
  },
  methods: {
    loadApiApp() {
      const url = `${config.VUE_APP_API_BASE_URL}/api-apps/${this.$route.params.id}`;

      this.loading = true;

      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      })
        .then((response) => {
          this.apiApp = response.data.apiApp;

          if (response.data.organization) {
            this.organization = response.data.organization;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
